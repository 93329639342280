import { service } from '../http'
import {
  BuyGoodsBack,
  BuyGoodsParams,
  ClassItem,
  ExchangeRecord,
  ExchangeRecordType,
  GetExchangeRecordsParams,
  GetListParams,
  ShopGoods,
  ProofValidParams,
  WandBox
} from './model'
import { PromiseListResponse, PromiseResponse, replaceToV2 } from '../common'

const BASE_URL = '/shop/api/v1'

export class ShopAPI {
  // 订单proof上传hash
  static async proofValid(params: ProofValidParams): PromiseResponse<void> {
    return service.post(`${BASE_URL}/order/proofValid`, params, {
      headers: {
        needEncrypt: '1'
      }
    })
  }
  // 购买商品
  static async buyGoods(params: BuyGoodsParams): PromiseResponse<BuyGoodsBack> {
    return service.post(`${replaceToV2(BASE_URL)}/order/create`, params)
  }
  // 商品列表
  static async getList(params: GetListParams): PromiseResponse<ShopGoods[]> {
    return service.post(`${BASE_URL}/goods/getList`, params)
  }
  // 获取wand盲盒
  static async getWandBox(): PromiseResponse<WandBox[]> {
    return service.post(`${BASE_URL}/goods/getTimeLimitedList`)
  }
  // 获取全部商品分类信息
  static async getClassList(): PromiseResponse<ClassItem[]> {
    return service.get(`${BASE_URL}/class/getList`)
  }
  // 获取兑换记录列表类型 - 搜索项
  static async getExchangeRecordsType(): PromiseResponse<ExchangeRecordType[]> {
    return service.post(`${BASE_URL}/order/getExchangeRecordsType`)
  }
  // 获取兑换记录列表类型 - 搜索项
  static async getExchangeRecords<T>(
    params: GetExchangeRecordsParams
  ): PromiseListResponse<{ records: T[] }> {
    return service.post(
      replaceToV2(`${BASE_URL}/order/getExchangeRecords`),
      params
    )
  }
}
