import { service } from '../http'
import { paramAddChain } from '@/utils/utils'
import {
  GetRankingLinksParams,
  ConfirmViewParams,
  GetQuestionParams,
  GetAnswerParams,
  CompleteQuizParams,
  GetTwitterTokenAndBindParams,
  GetDiscordTokenAndBindParams,
  GetTaskListParams,
  CompleteTaskParams,
  GetIdolListParams,
  SearchIdolParams,
  SearchIdolOptions,
  Questions,
  QuizResult,
  MediaInfo,
  TopIdol,
  RiddleConsumeParams,
  RiddleIdol,
  RiddleSubmitParams,
  MyAnswers,
  CorrectAnswer,
  GetUserRiddleStatusParams,
  UserRiddleStatus,
  GetMyAnswersParams,
  SetOpenPopStatusParams,
  GetCorrectAnswersParams,
  GetRiddleListParams,
  Banner,
  ActivitiesTime,
  ActivityInfo,
  ActivityTime,
  ActivityParticipateRecord,
  QuestionAnswer
} from './model'
import { PromiseListResponse, PromiseResponse, BaseParams } from '../common'
import { PAGE_SIZE } from '@/config'

const BASE_URL = '/task/api/v1'

export class TaskApi {
  // 获取任务列表
  static async getTaskList(params: GetTaskListParams) {
    return service.post(`${BASE_URL}/task/getTasksList`, params)
  }
  // 完成任务
  static async completeTask(params: CompleteTaskParams) {
    const data = paramAddChain(params)
    return service.post(`${BASE_URL}/task/completeAndClaimPoint`, data)
  }
  // 获取排名链接
  static async getRankingLinks(
    params: GetRankingLinksParams
  ): PromiseResponse<string> {
    return service.post(`${BASE_URL}/taskItem/getRankingLinks`, params)
  }
  // 确认查看榜单链接是否已经被点击
  static async confirmView(params: ConfirmViewParams): PromiseResponse<null> {
    return service.post(`${BASE_URL}/taskItem/confirmView`, params)
  }
  // 获取偶像团队及城市列表
  static async getCitiesAndTeams(): PromiseResponse<SearchIdolOptions> {
    return service.post(`${BASE_URL}/idolManagement/getSearchIdolOptions`)
  }
  // 获取题目
  static async getQuiz(params: GetQuestionParams): PromiseResponse<Questions> {
    return service.post(`${BASE_URL}/taskItem/getQuestion`, params)
  }
  // 获取题目的答案
  static async getAnswer(
    params: GetAnswerParams
  ): PromiseResponse<QuestionAnswer> {
    return service.post(`${BASE_URL}/taskItem/getAnswer`, params)
  }
  // 提交问答
  static async completeQuiz(
    params: CompleteQuizParams
  ): PromiseResponse<QuizResult> {
    return service.post(`${BASE_URL}/taskItem/completeExams`, params)
  }
  // 获取 twitter 授权页面
  static async requestTokenLink(): PromiseResponse<string> {
    return service.post(`${BASE_URL}/twitter/requestTokenLink`)
  }
  // 绑定twitter
  static async getTwitterTokenAndBind(
    params: GetTwitterTokenAndBindParams
  ): PromiseResponse<void> {
    return service.post(`${BASE_URL}/twitter/getTokenAndBind`, params)
  }
  // 获取twitter最新情报id
  static async getTwitterStatusId(accountId: string): PromiseResponse<string> {
    return service.post(`${BASE_URL}/twitter/getLatestTweetId`, { accountId })
  }
  // 绑定discord
  static async getDiscordTokenAndBind(
    params: GetDiscordTokenAndBindParams
  ): PromiseResponse<void> {
    return service.post(`${BASE_URL}/discord/getTokenAndBind`, params)
  }
  // 获取 discord 登录链接
  static async requestLoginLink(): PromiseResponse<string> {
    return service.post(`${BASE_URL}/discord/requestLoginLink`)
  }
  // 获取社交账号用户名
  static async getMediaInfo(accountId: string): PromiseResponse<MediaInfo> {
    return service.post(`${BASE_URL}/task/getMediaInfo`, { accountId })
  }
  // 解绑
  static async unbind(accountId: string, type: string): PromiseResponse<void> {
    let path = ``
    if (type === `Twitter`) {
      path = `${BASE_URL}/twitter/unBindTwitter`
    }
    if (type === `Discord`) {
      path = `${BASE_URL}/discord/unBindDiscord`
    }
    return service.post(path, { accountId })
  }
  static async getTop16Ranking(): PromiseResponse<TopIdol[]> {
    return service.post(`${BASE_URL}/idolManagement/getTop16Ranking`)
  }
  static async riddleConsume(
    params: RiddleConsumeParams
  ): PromiseResponse<{ status: boolean }> {
    return service.post(`${BASE_URL}/riddle/consume`, params)
  }
  static async getRiddleList(
    params: GetRiddleListParams
  ): PromiseResponse<RiddleIdol[]> {
    return service.post(`${BASE_URL}/riddle/getRiddleList`, params)
  }
  static async submitAnswers(
    params: RiddleSubmitParams
  ): PromiseResponse<{ status: boolean }> {
    return service.post(`${BASE_URL}/riddle/submitAnswers`, params)
  }
  static async getMyAnswers(
    params: GetMyAnswersParams
  ): PromiseResponse<MyAnswers> {
    return service.post(`${BASE_URL}/riddle/getMyAnswers`, params)
  }
  static async getCorrectAnswers(
    params: GetCorrectAnswersParams
  ): PromiseResponse<CorrectAnswer> {
    return service.post(`${BASE_URL}/riddle/getCorrectAnswers`, params)
  }
  static async getUserRiddleStatus(
    params: GetUserRiddleStatusParams
  ): PromiseResponse<UserRiddleStatus> {
    return service.post(`${BASE_URL}/riddle/getUserRiddleStatus`, params)
  }
  static async setOpenPopStatus(
    params: SetOpenPopStatusParams
  ): PromiseResponse<void> {
    return service.post(`${BASE_URL}/riddle/setOpenPopStatus`, params)
  }
  static async getBanner(type: number): PromiseResponse<Banner[]> {
    return service.post(`${BASE_URL}/taskItem/getBanner`, { type })
  }
  static async getActivitiesTime(
    taskId: number
  ): PromiseResponse<ActivitiesTime> {
    return service.post(`${BASE_URL}/taskItem/getActivitiesTime`, { taskId })
  }
  static async getActivityInfo(
    periods: number
  ): PromiseResponse<ActivityInfo[]> {
    return service.post(`${BASE_URL}/taskItem/getActivityInfo`, { periods })
  }
  static async getActivityTime(
    periods: number | string
  ): PromiseResponse<ActivityTime> {
    return service.post(`${BASE_URL}/taskItem/getActivityTime`, { periods })
  }
  static async getActivityParticipateRecords(): PromiseListResponse<{
    list: ActivityParticipateRecord[]
  }> {
    return service.post(`${BASE_URL}/taskItem/getActivityParticipateRecords`, {
      pageNum: 1,
      pageSize: PAGE_SIZE
    })
  }
  // 是否展示白名单按钮
  static async taskShowConfig(): PromiseResponse<{ whitelistQuery: number }> {
    return service.post(`${BASE_URL}/task/taskShowConfig`)
  }
}
