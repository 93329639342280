import React, { useEffect } from 'react'
import useModal from '@/hooks/useModal'
import LoginModal from '@/components/LoginModal/Login'
import RegisterModal from '@/components/LoginModal/Register'
import styles from './style.module.less'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Button'
import { useDisconnect } from 'wagmi'
import { errorNotice } from '@/common/tip'
import { getSearchParams } from '@/utils/utils'
import { useHistory, useLocation } from 'react-router-dom'

const NotConnectButton: React.FC = () => {
  const { t } = useTranslation()
  const [openLoginModal] = useModal(<LoginModal />, { maskClosable: false })
  const [openRegisterModal] = useModal(<RegisterModal />, {
    maskClosable: false
  })

  const { disconnectAsync } = useDisconnect()

  const { pathname } = useLocation()

  const history = useHistory()

  useEffect(() => {
    const params = getSearchParams()
    if (params.login) {
      history.push(pathname)
      openLoginModal()
    }
    if (params.register) {
      history.push(pathname)
      openRegisterModal()
    }
  }, [])

  const loginHandle = async () => {
    try {
      await disconnectAsync()
      openLoginModal()
    } catch (error) {
      errorNotice(error)
    }
  }

  const registerHandle = () => {
    openRegisterModal()
  }

  return (
    <div className={styles.notConnectButton}>
      <Button className={styles.btn} onClick={loginHandle} borderOnly>
        {t('login.loginUpperCase')}
      </Button>
      <Button className={styles.btn} onClick={registerHandle}>
        {t('login.registerUpperCase')}
      </Button>
    </div>
  )
}

export default NotConnectButton
