import { service } from '../http'
import { PromiseResponse } from '../common'
import { Navigate } from './model'

const BASE_URL = '/home/api/v1'

export class HomeAPI {
  static async getNavigation(): PromiseResponse<{ navigation: Navigate[] }> {
    return service.post(`${BASE_URL}/home/navigation`)
  }
}
