import { errorNotice } from '@/common/tip'
import { HomeAPI, Navigate } from '@/services/homeAPI'
import { create } from 'zustand'

type State = { navigates: Navigate[] }

type Actions = {
  getNavigates: () => void
}

const useNavigates = create<State & Actions>((set, get) => ({
  navigates: [],
  async getNavigates() {
    const res = await HomeAPI.getNavigation()
    if (res.data.code === 0) {
      set({ navigates: res.data.data.navigation })
    } else {
      errorNotice(res.data.message)
    }
  }
}))

export { useNavigates }
