import { useNavigates } from '@/store/useNavigates'
import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

const CustomRoute: React.FC<RouteProps> = (props) => {
  const { navigates } = useNavigates()

  const show = navigates.some((n) => n.path === props.path && n.visible)

  return show ? <Route {...props} /> : null
}

export default React.memo(CustomRoute)
