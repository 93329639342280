const configWeb3 = {
  test: {
    nftContractAddress:
      '0x4c7D2Eb176607500F01BE7efe266b5707EA758D0' as `0x${string}`, // 代币合约地址
    voteContractAddress:
      '0xdf1A5b16dD44A3d107CbC2ec60762087a48Ee8ea' as `0x${string}`, // 投票合约地址
    base: 0x0a
  },
  pre: {
    nftContractAddress:
      '0x6C1144514e8c367EeA0Ea8c06CcD84D5f0505853' as `0x${string}`, // 代币合约地址
    voteContractAddress:
      '0x0ba070cd6A186A1659F1B6c3c574E46FFD43155c' as `0x${string}`, // 投票合约地址
    base: 0x0a
  },
  prod: {
    nftContractAddress:
      '0xe402AbFeD58f2F6B8d1494b8F4b57E5Aeeab6747' as `0x${string}`, // 代币合约地址
    voteContractAddress:
      '0xF6bcFDA6021daf64037DD09d734B41FF7D73272d' as `0x${string}`, // 投票合约地址
    base: 0x0a
  }
}

function getConfig() {
  if (process.env.isTestOrDev) {
    return configWeb3.test
  }
  return process.env.isPre ? configWeb3.pre : configWeb3.prod
}

const config = getConfig()

const IMG_BASE_URL = 'https://static.meet48.xyz'

const WEB3_BLOCK_HASH_PATH = process.env.isTestOrDev
  ? 'https://testnet.opbnbscan.com/tx'
  : 'https://opbnbscan.com/tx'

const X_DEVICE_ID = 'X-Deviceid'

const PAGE_SIZE = 1000

export const THINKING_DATA_SERVER_URL = 'https://shushu.meet48.xyz'

export const THINKING_DATA_APP_ID = process.env.isTestOrDev
  ? 'debug-appid'
  : '1accb4657cd04307838e4198367183fa'

// 更新的时候记得更新index.html里的key
export const GOOGLE_RECAPTCHA_KEY = '6LcBZokqAAAAACK790AVVMa-aRq6035cmgZWostY'

export const DEVICE_TYPE = 'PC'

export const BEST7_AUDIO_PREFIX = 'https://static.meet48.xyz/resource'
export const BEST7_ALBUM_COVER_PREFIX =
  'https://static.meet48.xyz/resource/img/cp'

export { config, IMG_BASE_URL, X_DEVICE_ID, PAGE_SIZE, WEB3_BLOCK_HASH_PATH }
